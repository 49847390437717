import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Table, Tabs } from 'react-bootstrap'
import ReactGA from 'react-ga4'
import Skeleton from 'react-loading-skeleton'
import { Col, Row } from 'reactstrap'
import Carregando from '../components/Cards/Carregando'
import CardSemDados from '../components/Cards/SemDados'
import CollapseTurmas from '../components/Collapse/CollapseTurmas'
import Agrupamento from '../components/Other/AgrupaBoletos'
import Diversos from '../components/Other/Diversos'
import Servico from '../components/Other/Servicos'
import TableButtons from '../components/Other/TableButtons'
import TableButtonsCard from '../components/Other/TableButtonsCard'
import TableButtonsMD from '../components/Other/TableButtonsMD'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useAuth } from '../providers/auth'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

const userDec = process.env.REACT_APP_USER_API
const passDec = process.env.REACT_APP_USER_PSW

const api = process.env.REACT_APP_HOST
const api_2 = process.env.REACT_APP_HOST_2
const api_matriculei = process.env.REACT_APP_API_MATRICULEI

const Index = () => {
  const [key, setKey] = useState('open')
  const { user, setUser } = useAuth()
  const { height, width } = useWindowDimensions()
  const [isload, setload] = useState(false)
  const [isLoadTurmas, setisLoadTurmas] = useState(false)
  const [codperlet, setcodperlet] = useState(2021)
  const [codturma, setcodturma] = useState('')
  const [dadosturmas, setDadosturmas] = useState([])
  const [dadosmensalidades, setdadosMensalidades] = useState([])
  const [dadosOrdenados, setDadosOrdenados] = useState([])
  const [dadosmaterial, setdadosMaterial] = useState([])
  const [dadosagendados, setDadosagendados] = useState([])
  const [dadosFiltrados, setDadosFiltrados] = useState([])
  const [modalagendar, setModalagendar] = useState(false)
  const [isloadparcagenda, setIsloadParcAgenda] = useState(false)
  const [totmensalidades, setTotmensalidades] = useState(
    <Skeleton height={20} />
  )
  const [totmensalidadespagas, setTotmensalidadespagas] = useState(
    <Skeleton height={20} />
  )
  const [totmensalidadesabertas, setTotmensalidadesabertas] = useState(
    <Skeleton height={20} />
  )
  const [totmmaterial, setTotmmaterial] = useState(<Skeleton height={20} />)
  const [totmaterialpagas, setTotmaterialpagas] = useState(
    <Skeleton height={20} />
  )
  const [totmaterialabertas, setTotmaterialabertas] = useState(
    <Skeleton height={20} />
  )
  const [openCollapseIndex, setOpenCollapseIndex] = useState(null)
  const [abertosCount, setAbertosCount] = useState(0)
  const [gradientStyle, setGradientStyle] = useState({
    background: 'linear-gradient(to right, #fafafa, #f5f5f5)',
    width: '100%',
  })
  const [selected, setSelected] = useState('')

  const handleCollapseToggle = (index) => {
    if (openCollapseIndex === index) {
      setOpenCollapseIndex(null)
    } else {
      setOpenCollapseIndex(index)
    }
  }

  const listarMensalidades = async (ra, coligada, codPerlet, codTurma) => {
    setload(true)
    const myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    try {
      const response = await fetch(
        `${api}/listamensalidadesra?ra=${ra}&codperlet=${codPerlet}`,
        requestOptions
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const result = await response.json()
      // console.log('Dados do Mesalidade', result.dados);
      setdadosMensalidades(result.dados)
      setTotmensalidades(result.record_count)
      setload(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setdadosMensalidades([])
      setTotmensalidades([])
      setload(false)
    }
  }

  const getMaterialDidatico = async (ra, codperlet) => {
    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(
      `${api}/listamaterialra?ra=${ra}&codperlet=${codperlet}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setTotmmaterial(result.record_count)
        setdadosMaterial(result.dados)

        const resultado =
          result.dados &&
          result.dados.find(
            (rsdados) => rsdados.AGENDAMENTOSTATUS === 'PREAGENDADO'
          )

        if (resultado !== undefined) {
          /* Exibe o popup de MD Agendado */
          var myHeaders = new Headers()
          myHeaders.append(
            'Cookie',
            'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup'
          )

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

          fetch(
            `${api}/listamaterialagendado?ra=${ra}&codperlet=${codperlet}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              setIsloadParcAgenda(false)
              setDadosagendados(result.dados)
              setModalagendar(!modalagendar)
            })
            .catch((error) => {
              setIsloadParcAgenda(false)
              console.log('error', error)
            })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const contarRegistrosPorStatus = (data, status) => {
    if (status) {
      const lsitensFiltrados = data.filter((item) => item.LAN_STATUS === status)
      return lsitensFiltrados.length
    } else {
      return data.length
    }
  }

  const converterData = (dataString) => {
    const [dia, mes, ano] = dataString.split('/')
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`
  }

  const renderizarItensPorTurma = (data, status) => {
    const gruposPorTurma = {}

    // Agrupa os itens por CODTURMA
    data.forEach((item) => {
      if (!gruposPorTurma[item.CODTURMA]) {
        gruposPorTurma[item.CODTURMA] = []
      }
      gruposPorTurma[item.CODTURMA].push(item)
    })

    // Filtra os itens com base no status, se fornecido
    let itensFiltrados
    if (status) {
      itensFiltrados = Object.values(gruposPorTurma)
        .flatMap((item) => item)
        .filter((item) => item.LAN_STATUS === status)
    } else {
      itensFiltrados = data
    }

    const itensOrdenados = itensFiltrados.sort((a, b) => {
      const dataA = converterData(a.VENCIMENTO)
      const dataB = converterData(b.VENCIMENTO)
      //return dataA.localeCompare(dataB); // Crescente
      return dataB.localeCompare(dataA) // Decrescente
    })

    // Atualiza o estado com os itens filtrados e ordenados
    // setDadosOrdenados(itensOrdenados);

    // Renderiza os grupos
    return (
      <div>
        {Object.keys(gruposPorTurma).map((codTurma, id) => {
          const itensPorTurma = itensOrdenados.filter(
            (i) => i.CODTURMA === codTurma
          )
          // Verifica se codTurma está preenchido
          const renderCollapse = codTurma ? (
            <CollapseTurmas
              key={codTurma}
              title={`${codTurma} - ${gruposPorTurma[codTurma][0].NOMETURMA}`}
              isOpenByDefault={id === 0}
              ra={gruposPorTurma[codTurma][0].RA}
              codcoligada={gruposPorTurma[codTurma][0].CODCOLIGADA}
              codfilial={gruposPorTurma[codTurma][0].CODFILIAL}
              codturma={codTurma}
              codperlet={gruposPorTurma[codTurma][0].CODPERLET}
              isOpen={openCollapseIndex === id}
            >
              <div className="col">
                <Card className="shadow" style={{ paddingBottom: '10px' }}>
                  {/* <Card.Header className="border-0">
                    <h3 className="mb-0">Mensalidades e Serviços - {user.codperlet}</h3>
                  </Card.Header> */}
                  {itensPorTurma.length > 0 ? (
                    true ? (
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                        style={{ fontSize: '0.7rem' }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                fontWeight: '600',
                                textAlign: 'center',
                                width: '5%',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Status
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                width: '40%',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Descrição
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                width: '10%',
                                textAlign: 'center',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Boleto
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                width: '10%',
                                textAlign: 'center',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Vencimento
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                textAlign: 'left',
                                width: '10%',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Valor (R$)
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                textAlign: 'left',
                                width: '10%',
                                height: '30px',
                                textTransform: 'capitalize',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Valor pago(R$)
                            </th>
                            <th
                              style={{
                                fontWeight: '600',
                                textAlign: 'center',
                                height: '30px',
                                fontSize: '0.8rem',
                                borderBottom: '1px solid #d1cfcf',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            >
                              Pagamento
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {itensPorTurma.map((itemBoleto, index) => {
                            const [dia, mes, ano] =
                              itemBoleto.VENCIMENTO.split('/')
                            const dataVencimento = new Date(
                              `${ano}-${mes}-${dia}`
                            )
                            const statusColors = {
                              Aberto:
                                new Date() > dataVencimento
                                  ? '#f5365c'
                                  : '#ffd24d',
                              Pago: '#2dce89',
                            }

                            // Obtendo a cor do status atual
                            const statusColor =
                              statusColors[itemBoleto.LAN_STATUS] || 'black'

                            // Definindo se os botões devem estar desabilitados com base no status
                            const isPago = itemBoleto.LAN_STATUS === 'Pago'

                            return (
                              <React.Fragment key={index}>
                                {itemBoleto.QTDLANCAMENTOS > 1 ? (
                                  <Agrupamento
                                    key={index}
                                    itemBoleto={itemBoleto}
                                    user={user}
                                  />
                                ) : (
                                  <tr key={index} style={{ height: '20px' }}>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div
                                          id={`statusTooltip${index}`}
                                          style={{
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '50%',
                                            backgroundColor: statusColor,
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {itemBoleto.QTDLANCAMENTOS > 1 ? (
                                        <Diversos
                                          idboleto={itemBoleto.IDBOLETO}
                                        />
                                      ) : itemBoleto.TIPO !== 'MD' ? (
                                        <Servico
                                          codcoligada={itemBoleto.CODCOLIGADA}
                                          idboleto={itemBoleto.IDBOLETO}
                                          idlan={itemBoleto.IDLAN}
                                        />
                                      ) : (
                                        'Material didático'
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {itemBoleto.IDBOLETO}
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {itemBoleto.VENCIMENTO}
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'left',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {parseFloat(itemBoleto.VALOR)
                                        .toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        .replace('R$', '')}
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'left',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {itemBoleto.VALORBAIXA != null
                                        ? parseFloat(itemBoleto.VALORBAIXA)
                                            .toLocaleString('pt-BR', {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                            .replace('R$', '')
                                        : '-'}
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '220px',
                                          margin: '0 auto',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {itemBoleto.TIPO !== 'MD' ? (
                                          <TableButtons
                                            itemBoleto={itemBoleto}
                                            user={user}
                                          />
                                        ) : (
                                          <TableButtonsMD
                                            itemMaterial={itemBoleto}
                                            user={user}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </tbody>
                        <style>
                          {`
                            .table-bordered td {
                              border: 0px solid #e9ecef;
                            }
                            .table th, .table td {
                              padding: 0.4rem;
                              vertical-align: top;
                            }
                            .btn-block + .btn-block {
                              margin-top: 0rem;
                            }
                          `}
                        </style>
                      </Table>
                    ) : (
                      <TableButtonsCard
                        dadosmensalidades={dadosmensalidades}
                        user={user}
                      />
                    )
                  ) : (
                    <CardSemDados />
                  )}
                </Card>
              </div>
            </CollapseTurmas>
          ) : null
          return renderCollapse
        })}
      </div>
    )
  }

  function onChange(ev) {
    var resultado = ev.target.value
    setUser({
      ...user,
      codperlet: resultado[0],
    })
    localStorage.setItem('@meuboleto/perlet', resultado) // codperlet

    setTotmensalidades(<Skeleton height={20} />)
    setTotmensalidadespagas(<Skeleton height={20} />)
    setTotmensalidadesabertas(<Skeleton height={20} />)
    setTotmmaterial(<Skeleton height={20} />)
    setTotmaterialpagas(<Skeleton height={20} />)
    setTotmaterialabertas(<Skeleton height={20} />)

    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      `${api}/listamensalidadesra?ra=${localStorage.getItem(
        '@meuboleto/ra'
      )}&codperlet=${resultado}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem(
          '@meuboleto/rsmensalidades',
          JSON.stringify(result.dados)
        )
        setdadosMensalidades(result.dados)
        setTotmensalidades(result.record_count)
      })
      .catch((error) => console.log('error', error))

    // Material Didatico on change
    getMaterialDidatico(localStorage.getItem('@meuboleto/ra'), resultado)
    setSelected(resultado)
  }

  useEffect(() => {
    // Repasso os dados para o Google Analitcs
    ReactGA.pageview(window.location.pathname + window.location.search)

    const urlParams = new URLSearchParams(window.location.search)
    const perlet = urlParams.get('perlet') * 1 || new Date().getFullYear()

    setisLoadTurmas(true)
    fetch(`${api}/getlistaturmas?ra=${localStorage.getItem('@meuboleto/ra')}`)
      .then((response) => response.json())
      .then((result) => {
        const hasPerlet = result.dados.some(
          (item) => item.CODPERLET * 1 === perlet
        )
        const codPerlet = hasPerlet ? perlet : result.dados[0].CODPERLET
        setisLoadTurmas(false)
        setDadosturmas(result.dados)
        setUser({
          ...user,
          codperlet: codPerlet,
        })
        setcodperlet(codPerlet)
        setcodturma(result.dados[0].CODTURMA)

        listarMensalidades(
          localStorage.getItem('@meuboleto/ra'),
          result.dados[0].CODCOLIGADA,
          codPerlet,
          result.dados[0].CODTURMA
        )
        getMaterialDidatico(localStorage.getItem('@meuboleto/ra'), codPerlet)

        setSelected(codPerlet)
      })
      .catch((error) => console.log('error', error))

    const { background } = gradientStyle
    document.body.style.background = background

    return () => {
      document.body.classList.remove('bg-default')
      document.body.style.background = '' // Limpa a cor quando o componente é desmontado
    }
  }, [])

  useEffect(() => {
    // Verifica se ambos os arrays estão preenchidos
    if (dadosmensalidades.length > 0 && dadosmaterial.length > 0) {
      const dadosCombinados = [...dadosmensalidades, ...dadosmaterial]
      const boletosOrdenados = dadosCombinados.sort((a, b) => {
        const dataA = converterData(a.VENCIMENTO)
        const dataB = converterData(b.VENCIMENTO)
        return dataA.localeCompare(dataB)
      })

      setDadosOrdenados(boletosOrdenados)
    } else if (dadosmensalidades.length > 0) {
      const boletosOrdenados = dadosmensalidades.sort((a, b) => {
        const dataA = converterData(a.VENCIMENTO)
        const dataB = converterData(b.VENCIMENTO)
        return dataA.localeCompare(dataB)
      })

      setDadosOrdenados(boletosOrdenados)
    } else if (dadosmaterial.length > 0) {
      const boletosOrdenados = dadosmaterial.sort((a, b) => {
        const dataA = converterData(a.VENCIMENTO)
        const dataB = converterData(b.VENCIMENTO)
        return dataA.localeCompare(dataB)
      })

      setDadosOrdenados(boletosOrdenados)
    } else {
      setDadosOrdenados([])
    }
  }, [dadosmensalidades, dadosmaterial])

  return (
    <>
      <Container style={{ paddingTop: '60px' }} fluid>
        <br />
        <div className="header-body">
          <Row>
            <Col lg="6" xl="4">
              <form>
                <div className="form-group">
                  <select
                    id="codturma"
                    name="codturma"
                    className="form-control input-shadow"
                    style={{
                      height: '30px',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                    onChange={onChange}
                    value={selected}
                    required
                  >
                    {isLoadTurmas ? (
                      <option>Carregando...</option>
                    ) : (
                      dadosturmas.map((itemTurma) => (
                        <option
                          key={itemTurma.CODTURMA}
                          value={itemTurma.CODPERLET}
                        >
                          Período letivo de {itemTurma.CODPERLET}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div>
          <div className="content">
            <Tabs
              id="controlled-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="open"
                title={
                  <span>
                    <div
                      style={{
                        display: 'inline-block',
                        marginRight: '5px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-16px',
                          right: '-20px',
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: '#f4375e',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <i
                          className="fas fa-calendar-alt"
                          style={{ color: 'white', fontSize: '15px' }}
                        ></i>
                      </div>
                    </div>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        fontSize: '0.8rem',
                        marginLeft: '20px',
                      }}
                    >
                      Em aberto
                    </span>{' '}
                    (
                    <span style={{ fontWeight: 'bold' }}>
                      {contarRegistrosPorStatus(dadosOrdenados, 'Aberto')}
                    </span>
                    )
                  </span>
                }
              >
                {/* <RenderLista items={dadosOrdenados}/> */}
                {isload ? (
                  <Carregando />
                ) : contarRegistrosPorStatus(dadosOrdenados, 'Aberto') > 0 ? (
                  renderizarItensPorTurma(dadosOrdenados, 'Aberto')
                ) : (
                  <CardSemDados />
                )}
              </Tab>
              <Tab
                eventKey="paid"
                title={
                  <span>
                    <div
                      style={{
                        display: 'inline-block',
                        marginRight: '5px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-16px',
                          right: '-20px',
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: '#2dce89',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <i
                          className="fas fa-dollar-sign"
                          style={{ color: 'white', fontSize: '15px' }}
                        ></i>
                      </div>
                    </div>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        fontSize: '0.8rem',
                        marginLeft: '20px',
                      }}
                    >
                      Pagos
                    </span>{' '}
                    (
                    <span style={{ fontWeight: 'bold' }}>
                      {contarRegistrosPorStatus(dadosOrdenados, 'Pago')}
                    </span>
                    )
                  </span>
                }
              >
                {isload ? (
                  <Carregando />
                ) : contarRegistrosPorStatus(dadosOrdenados, 'Pago') > 0 ? (
                  renderizarItensPorTurma(dadosOrdenados, 'Pago')
                ) : (
                  <CardSemDados />
                )}
              </Tab>
              <Tab
                eventKey="all"
                title={
                  <span>
                    <div
                      style={{
                        display: 'inline-block',
                        marginRight: '5px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-16px',
                          right: '-20px',
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: '#73aafc',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <i
                          className="fas fa-file-invoice-dollar"
                          style={{ color: 'white', fontSize: '15px' }}
                        ></i>
                      </div>
                    </div>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        fontSize: '0.8rem',
                        marginLeft: '20px',
                      }}
                    >
                      Todos
                    </span>{' '}
                    (
                    <span style={{ fontWeight: 'bold' }}>
                      {contarRegistrosPorStatus(dadosOrdenados, '')}
                    </span>
                    )
                  </span>
                }
              >
                {isload ? (
                  <Carregando />
                ) : contarRegistrosPorStatus(dadosOrdenados, '') > 0 ? (
                  renderizarItensPorTurma(dadosOrdenados, '')
                ) : (
                  <CardSemDados msg="Você ainda não possui nenhum boleto para este ano letivo" />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Index
