import React, { useState } from 'react'
import BtnBoleto from './BtnBoleto'
import BtnCartao from './BtnCartao'
import BtnPix from './BtnPix'
import BtnNfse from './BtnNfse'
import Servico from './Servicos'
import Diversos from '../../components/Other/Diversos'
import CardSemDados from '../../components/Cards/SemDados'
import SolicitarBoletos from './BtnSolicitarBoleto'
import { lighten } from 'polished'
import { Card, Row, Col, CardBody, Badge, Button } from 'reactstrap'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'

const api = process.env.REACT_APP_HOST

const TableButtonsCard = ({ dadosmensalidades, user }) => {
  // Identidade da Marca
  const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
    ? localStorage.getItem('@meuboleto/bgcolor')
    : '#1A508C'
  const lighterPrimaryColor = lighten(0.2, primaryColor)
  const [gradientStyle, setGradientStyle] = useState({
    background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
    width: '100%',
  })

  const temAnuidade = (servico) => {
    // if (servico.length > 0) {
    //   const servicoNomeMin = servico.toLowerCase()
    //   return servicoNomeMin.includes('anuidade')
    // }
    return false
  }

  return (
    <>
      <Card>
        <CardBody>
          {dadosmensalidades.length > 0 ? (
            dadosmensalidades.map((itemBoleto, index) => {
              var hj = new Date()
              var vencto = new Date(itemBoleto.DTVENCTO) // já está no formato "DD/MM/YYYY"
              var statusbol = ''
              var statucnab = itemBoleto.CNABSTATUS

              switch (statucnab) {
                case 0:
                  statusbol = 'Não Remetido'
                  break
                case 1:
                  statusbol = 'Remetido'
                  break
                case 2:
                  statusbol = 'Registrado'
                  break
                case 3:
                  statusbol = 'Recusado'
                  break
                case 4:
                  statusbol = 'Baixado'
                  break
                case 5:
                  statusbol = 'Registrado Online'
                  break
                case 6:
                  statusbol = 'Cancelado'
                  break
                case 7:
                  statusbol = 'Pendente Remessa'
                  break
                default:
                  console.log('default')
              }
              return (
                <Row className="icon-examples">
                  <Col class="col-12">
                    <div
                      style={{
                        backgroundColor: '#f5f5f5',
                        borderRadius: 5,
                        color: '#32325d',
                        fontSize: 18,
                        margin: 0,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#ebeced',
                          height: '28px',
                        }}
                      >
                        <span
                          style={{
                            float: 'left',
                            color: '#707070',
                            textAlign: 'left',
                            fontSize: 14,
                            marginTop: 3,
                            marginLeft: 10,
                          }}
                        >
                          Vencimento: {itemBoleto.VENCIMENTO}
                        </span>
                        <span
                          style={{
                            float: 'right',
                            color: '#707070',
                            textAlign: 'left',
                            fontSize: 12,
                          }}
                        >
                          <Badge color="" className="badge-dot mr-4">
                            {itemBoleto.BTN_STATUS === 'Pago' ? (
                              <>
                                <i className="bg-success" />
                                {itemBoleto.LAN_STATUS}
                              </>
                            ) : null}

                            {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                              <>
                                <i className="bg-primary" />
                                {itemBoleto.LAN_STATUS}
                              </>
                            ) : null}

                            {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                              <>
                                <i className="bg-primary" />
                                Indisponível
                              </>
                            ) : null}

                            {itemBoleto.BTN_STATUS === 'BoletonaoRegistrado' ? (
                              <>
                                <i className="bg-primary" />
                                {itemBoleto.LAN_STATUS}
                              </>
                            ) : null}
                          </Badge>
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: 10,
                          marginTop: 5,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <a className="avatar rounded-circle mr-3">
                          {itemBoleto.STATUS === 1 ? (
                            <img
                              alt="..."
                              src={
                                require('../../assets/img/theme/pago.jpg')
                                  .default
                              }
                            />
                          ) : vencto < hj ? (
                            itemBoleto.CNABSTATUS === 2 ? (
                              <img
                                alt="..."
                                src={
                                  require('../../assets/img/theme/vencido.jpg')
                                    .default
                                }
                              />
                            ) : (
                              <img
                                alt="..."
                                src={
                                  require('../../assets/img/theme/indiponivel.jpg')
                                    .default
                                }
                              />
                            )
                          ) : (
                            <img
                              alt="..."
                              src={
                                require('../../assets/img/theme/imprimir.jpg')
                                  .default
                              }
                            />
                          )}
                        </a>
                        <span
                          style={{
                            fontSize: 14,
                            marginTop: 10,
                            color: '#000',
                          }}
                        >
                          <span className="mb-0 text-sm">
                            {itemBoleto.QTDLANCAMENTOS > 1 ? (
                              <>
                                {' '}
                                <span>{itemBoleto.VALOR}</span> -{' '}
                                <Diversos idboleto={itemBoleto.IDBOLETO} />{' '}
                              </>
                            ) : (
                              <>
                                {' '}
                                <span>{itemBoleto.VALOR}</span> -{' '}
                                <Servico idboleto={itemBoleto.IDBOLETO} />{' '}
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                        className="align-items-center"
                      >
                        {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                          <>
                            <BtnBoleto
                              ra={itemBoleto.RA}
                              codcoligada={itemBoleto.CODCOLIGADA}
                              codfilial={itemBoleto.CODFILIAL}
                              codperlet={itemBoleto.CODPERLET}
                              codturma={itemBoleto.CODTURMA}
                              idboleto={itemBoleto.IDBOLETO}
                              statusboleto={statucnab}
                              ipte={itemBoleto.IPTE}
                              codbarras={itemBoleto.CODIGOBARRAS}
                              tipo="mensalidade"
                            />
                            <br />
                            {temAnuidade(itemBoleto.SERVICONOME) ? null : (
                              <BtnCartao
                                ra={itemBoleto.RA}
                                codcoligada={itemBoleto.CODCOLIGADA}
                                codfilial={itemBoleto.CODFILIAL}
                                codperlet={itemBoleto.CODPERLET}
                                codturma={itemBoleto.CODTURMA}
                                idboleto={itemBoleto.IDBOLETO}
                                tipoparcelamento="Mensalidade"
                              />
                            )}
                            {
                              // <BtnPix
                              //   idboleto={itemBoleto.IDBOLETO}
                              //   tipoparcelamento="Mensalidade"
                              // />
                            }
                          </>
                        ) : null}

                        {itemBoleto.BTN_STATUS === 'NExibir' ? <></> : null}

                        {itemBoleto.BTN_STATUS === 'Pago' ? (
                          <>
                            <Button
                              variant="success"
                              className={`btn mr-1`}
                              style={{
                                cursor: 'pointer',
                                fontSize: '0.7rem',
                                backgroundColor: primaryColor,
                                color: '#fff',
                                width: '50%',
                                padding: '6px',
                              }}
                            >
                              <i
                                className="fas fa-dollar-sign"
                                style={{ marginRight: '5px' }}
                              ></i>{' '}
                              Pago
                            </Button>
                          </>
                        ) : null}

                        {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                          <>
                            <SolicitarBoletos
                              codcoligada={itemBoleto.CODCOLIGADA}
                              statucnab={itemBoleto.CNABSTATUS}
                              idboleto={itemBoleto.IDBOLETO}
                              idlan={itemBoleto.IDLAN}
                            />
                            <br />
                            {temAnuidade(itemBoleto.SERVICONOME) ? null : (
                              <BtnCartao
                                ra={itemBoleto.RA}
                                codcoligada={itemBoleto.CODCOLIGADA}
                                codfilial={itemBoleto.CODFILIAL}
                                codperlet={itemBoleto.CODPERLET}
                                codturma={itemBoleto.CODTURMA}
                                idboleto={itemBoleto.IDBOLETO}
                                tipoparcelamento="Mensalidade"
                              />
                            )}
                            {
                              // <BtnPix
                              //   idboleto={itemBoleto.IDBOLETO}
                              //   tipoparcelamento="Mensalidade"
                              // />
                            }
                          </>
                        ) : null}

                        {itemBoleto.BTN_STATUS === 'BoletonaoRegistrado' ? (
                          <>
                            {
                              // Habilitado somente para o Banco do Brasil
                              itemBoleto.NUMBANCO === '001' ? (
                                <SolicitarBoletos
                                  codcoligada={itemBoleto.CODCOLIGADA}
                                  statucnab={itemBoleto.CNABSTATUS}
                                  idboleto={itemBoleto.IDBOLETO}
                                  idlan={itemBoleto.IDLAN}
                                />
                              ) : (
                                <BtnBoleto
                                  ra={itemBoleto.RA}
                                  codcoligada={itemBoleto.CODCOLIGADA}
                                  idboleto={itemBoleto.IDBOLETO}
                                  statusboleto={statucnab}
                                  ipte={itemBoleto.IPTE}
                                  codbarras={itemBoleto.CODIGOBARRAS}
                                  tipo="mensalidade"
                                  acao="solicitar"
                                />
                              )
                            }
                            <br />
                            {temAnuidade(itemBoleto.SERVICONOME) ? null : (
                              <BtnCartao
                                ra={itemBoleto.RA}
                                codcoligada={itemBoleto.CODCOLIGADA}
                                codfilial={itemBoleto.CODFILIAL}
                                codperlet={itemBoleto.CODPERLET}
                                codturma={itemBoleto.CODTURMA}
                                idboleto={itemBoleto.IDBOLETO}
                                tipoparcelamento="Mensalidade"
                              />
                            )}
                          </>
                        ) : null}
                      </div>
                      <div
                        style={{
                          height: 5,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )
            })
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {dadosmensalidades.length === 0 ? (
                <span>
                  <Loader
                    type="ThreeDots"
                    color="#9e9e9e"
                    height={50}
                    width={50}
                    // timeout={3000} //3 secs
                  />
                </span>
              ) : (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <span>
                      <CardSemDados />
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TableButtonsCard
